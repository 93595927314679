export const infoCol = [
  {
    label: '用户名',
    prop: 'nickName'
  },
  {
    label: '手机(登录账号)',
    prop: 'accountName'
  },
  {
    label: '邮箱',
    prop: 'email'
  },
  {
    label: '分销商类型',
    prop: 'isProductPublic'
  },
  {
    label: '会员等级',
    prop: 'levelName'
  },
  {
    label: '采购额',
    prop: 'salesAmount'
  },
  {
    label: '余额',
    prop: 'accountAmount'
  },
  {
    label: '账号状态',
    prop: 'isFreeze'
  }
]

export const ISPRODUCTPUBLIC = ['分销商','公共产品上新']

export const tableCols = [
  {
    label: '内容',
    prop: 'content',
    slotName: 'contentSlot'
  },
  {
    label: '时间',
    prop: 'date'
  },
  {
    label: '操作账号',
    prop: 'accountName'
  }
] 

export const Reasons = {
  ['uploadViolation']: '上传违法违规图片',
  ['badCancel']: '多次取消订单',
  ['badMaintain']: '多次账号恶意维权',
  ['otherReason']: '其他原因'
}